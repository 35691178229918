<template>
  <div class="w-full note-content-container">
    <div class="flex items-center justify-between">
      <p class="note-time">
        {{ $dayjs(content.create_time * 1000).format("MMMM D, YYYY") }}
      </p>
      <img src="../assets/arrow.png" class="arrow-icon" />
    </div>
    <div class="note-title">{{ content.decryptTitle }}</div>
    <div
      class="note-content"
      v-html="formatContent(content.decryptContent)"
    ></div>
    <div class="tags-container">
      <div
        v-for="(tag, index) in content.label.filter((i) => !!i)"
        class="tag"
        :key="index"
        :style="{
          color: TagColorList[index % TagColorList.length].color,
          background: TagColorList[index % TagColorList.length].background,
        }"
      >
        <div class="tag-text">{{ tag }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { TagColorList } from "../utils/common";
export default {
  name: "Note",
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      TagColorList,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    formatContent(content) {
      let new_content = content.replaceAll("\n", "<br>");
      return new_content;
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.note-content-container {
  border-radius: 12px;
  border: 1px solid #edf2f7;
  padding: 16px;
  cursor: pointer;
  font-family: Poppins;
  height: 240px;
  position: relative;
  display: flex;
  flex-direction: column;

  .note-time {
    color: #005a64;
    font-size: 12px;
    font-weight: 400;
  }

  .arrow-icon {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
  }

  .note-title {
    margin: 16px 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #1a202c;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .note-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    flex: 1;
  }

  .tags-container {
    display: flex;
    gap: 8px;
    height: 34px;
    overflow-x: auto;
    margin-top: 12px;

    .tag {
      max-width: 200px;
      flex-shrink: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      border-radius: 8px;
      font-size: 12px;
      padding: 8px 12px;

      .tag-text {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
