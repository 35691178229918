<template>
  <div
    class="view-root view-content bg-white notes-list-wrapper"
    style="box-sizing: border-box"
  >
    <div class="placeholder"></div>
    <div class="flex justify-between items-center mb-6">
      <p class="title">My Refffs</p>
      <Avatar @export="doExportPdf()" />
    </div>
    <div class="note-container">
      <div class="content-container">
        <div class="w-full flex items-center justify-between">
          <div class="notification flex items-center">
            <img src="../../assets/plus.png" class="notice-icon" />
            <p class="notice-content">
              {{
                remindList.length === 0
                  ? "You haven't received any information yet."
                  : `Your next wake-up email will come on ${$dayjs(
                      remindList[0].remind_time * 1000
                    ).format("MMMM D, YYYY")}`
              }}
            </p>
            <!-- <img src="../../assets/arrow.png" class="arrow-icon" /> -->
          </div>
          <div class="flex gap-3 ml-3">
            <div class="custom-btn" @click="gotoAddNote">
              <img class="btn-icon" src="../../assets/add.png" />
              <p>New Refff</p>
            </div>
            <!-- <div class="custom-btn export-btn" @click="doExportPdf()">
              <img class="btn-icon" src="../../assets/share.png" />
              <p>Export</p>
            </div> -->
          </div>
        </div>
        <div
          v-for="(item, index) in dataList"
          :class="[
            { firstItem: index === 0, single: dataList.length === 1 },
            'note-item',
          ]"
          :key="index"
          @click="gotoDetail(item)"
        >
          <Note :content="item" />
        </div>
        <div
          v-if="dataList.length === 0"
          class="empty-container flex w-full items-center justify-center mt-6"
        >
          <img src="../../assets/empty.png" class="empty-img mb-6" />
          <p class="empty-title">
            You haven't written down any precious thoughts yet
          </p>
          <p class="empty-title mb-2">
            Let it continuously inspire you in the future
          </p>
          <div class="w-full p-3">
            <p class="empty-desc" style="color: #1a202c">
              Are you hesitant to try a new note-taking app? Here are some
              confidence boosters:
            </p>
            <p class="empty-desc">
              - Your note contents are encrypted and secured during transmission
              and storage.
            </p>
            <p class="empty-desc">
              - Easily export all your notes to PDF with the "Export" function
              conveniently located next to the ADD button in the upper right
              corner.
            </p>
            <p class="empty-desc">
              - We are dedicated to promptly addressing issues and making
              iterative improvements to enhance your experience.
            </p>
          </div>
        </div>
      </div>
      <div class="search-container">
        <p class="search-title">Find Your Refffs</p>
        <p class="search-tips">Search through title, body text or tags</p>
        <div class="search-input">
          <img class="search-icon" src="../../assets/search_icon.png" />
          <el-input
            v-model="keyword"
            placeholder="Search..."
            @keyup.enter.native="onSearch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyNotes } from "../../api/reff";
import { getUserId } from "../../utils/store";
import { decryptValue } from "../../utils/common";
import "../../assets/common/common.css";
import "../../assets/common/font.css";
import html2canvas from "html2canvas";
import { Loading } from "element-ui";
import Avatar from "../../components/Avatar.vue";
import Note from "../../components/Note.vue";
export default {
  name: "index",
  components: {
    Avatar,
    Note,
  },
  data() {
    return {
      dataList: [],
      keyword: "",
      showPlaceholder: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    remindList() {
      const reminds = this.dataList
        .filter((i) => i.reminds)
        .map((i) => i.reminds[i.remindIndex]);
      let sortResult = reminds.sort((a, b) => a.remind_time - b.remind_time);
      return sortResult;
    },
  },
  methods: {
    onSearch(e) {
      const keyCode = window.event ? e.keyCode : e.which;

      if (keyCode === 13) {
        let loadingInstance = Loading.service({
          fullscreen: true,
          background: "rgba(0, 0, 0, 0.8)",
          spinner: "el-icon-loading",
          text: "Loading data",
        });
        getMyNotes(getUserId())
          .then((res) => {
            let result = res.data.data;
            let notes = [];
            for (let i = 0; i < result.length; i++) {
              let content = result[i];
              let des_content_new = content.des_content_new;
              let decryptContent = this.doDecryptValue(des_content_new);
              let des_title_new = content.des_title_new;
              let decryptTitle = this.doDecryptValue(des_title_new);
              content["remindIndex"] = content.reminds.findIndex(
                (i) => i.push_status === "no"
              );
              content["decryptContent"] = decryptContent;
              content["decryptTitle"] = decryptTitle;
              content["show_type"] = "text";
              content["label"] = (content.des_labels_new || []).map((item) =>
                this.doDecryptValue(item)
              );
              notes.push(content);
            }
            if (!this.keyword) {
              this.dataList = notes;
            } else {
              this.dataList = notes.filter(
                (i) =>
                  i.label.some((j) => j.includes(this.keyword)) ||
                  i.decryptContent.includes(this.keyword) ||
                  i.decryptTitle.includes(this.keyword)
              );
            }
          })
          .finally(() => {
            loadingInstance.close();
          });
      }
    },
    doExportPdf() {
      // this.$message.warning("This function will be coming soon...");
      let route = this.$router.resolve({
        path: "/export",
      });
      window.open(route.href, "_blank");
      // this.$router.push('/export')
    },

    gotoAddNote() {
      this.$router.push({
        path: "/home/add",
      });
    },
    gotoDetail(card) {
      this.$router.push({
        path: "/home/view-refff",
        query: {
          id: card._id.$id,
        },
      });
    },
    fetchData() {
      this.dataList = [];
      let that = this;
      let loadingInstance = Loading.service({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.8)",
        spinner: "el-icon-loading",
        text: "Loading data",
      });
      getMyNotes(getUserId())
        .then(async (res) => {
          let result = res.data.data;
          for (let i = 0; i < result.length; i++) {
            let content = result[i];
            let des_content_new = content.des_content_new;
            let decryptContent = this.doDecryptValue(des_content_new);
            let des_title_new = content.des_title_new;
            let decryptTitle = this.doDecryptValue(des_title_new);
            content["remindIndex"] = content.reminds.findIndex(
              (i) => i.push_status === "no"
            );
            content["decryptContent"] = decryptContent;
            content["decryptTitle"] = decryptTitle;
            content["show_type"] = "text";
            content["label"] = (content.des_labels_new || []).map((item) =>
              this.doDecryptValue(item)
            );
            this.dataList.push(content);
          }
          if (this.dataList.length <= 0) {
            this.showPlaceholder = true;
          } else {
            this.showPlaceholder = false;
          }
        })
        .finally(() => {
          loadingInstance.close();
        });
    },
    async refreshNewData() {
      var opts = {
        dpi: window.devicePixelRatio * 2,
        scale: 2,
        width: 270,
        height: 360,
        allowTaint: true,
        useCORS: true,
      };
      for (let i = 0; i < this.dataList.length; i++) {
        let content = this.dataList[i];
        let id = "note" + (i + 1);
        let result = await html2canvas(document.getElementById(id), opts);
        let url = result.toDataURL();
        content["cover_pic_address"] = url;
        content["show_type"] = "image";
        this.dataList[i] = content;
      }
    },
    doDecryptValue(encrypted) {
      return decryptValue(encrypted);
    },
  },
};
</script>

<style scoped lang="scss">
.placeholder {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 54.17%);
  z-index: 100;
}

.title {
  font-family: "Poppins";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  color: #1a202c;
}

.notes-list-wrapper {
  padding: 32px;
  overflow-y: scroll;
  position: relative;
}

.notification {
  font-family: "Poppins";
  color: rgba(26, 32, 44, 0.5);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 48px;
  border-radius: 12px;
  background: #f4f4f4;
  padding: 0 12px;
  flex: 1;

  .notice-icon {
    width: 16px;
    height: 16px;
  }

  .arrow-icon {
    width: 16px;
    height: 16px;
  }

  .notice-content {
    margin: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.custom-btn {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  color: white;
  font-family: Poppins;
  background: #ec652b;
  height: 48px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;

  &.export-btn {
    color: #005a64;
    border: 2px solid #005a64;
    height: 48px;
    background: white;

    .btn-icon {
      width: 20px;
      height: 20px;
    }
  }

  .btn-icon {
    width: 16px;
    height: 16px;
  }
}

.note-container {
  display: flex;
  gap: 24px;

  .content-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 16px;

    // height: calc(100vh - 106px);
    .note-item {
      width: 100%;
    }
  }

  .search-container {
    width: 360px;
    height: fit-content;
    font-family: "Poppins";
    background: #f4f4f4;
    border-radius: 12px;
    padding: 24px;

    .search-title {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      color: #1a202c;
      margin-bottom: 8px;
    }

    .search-tips {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    .search-input {
      width: 100%;
      box-shadow: 4px 8px 16px 0px rgba(93, 106, 131, 0.02);
      background: #fff;
      margin: 24px 0 24px;
      border-radius: 12px;
      height: 48px;
      display: flex;
      align-items: center;

      .search-icon {
        width: 16px;
        height: 16px;
        margin-left: 12px;
      }

      &::v-deep .el-input__inner {
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        background: transparent;
      }
    }
  }
}

@media (max-width: 1200px) {
  .note-container {
    flex-direction: column-reverse;

    .search-container {
      width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .note-container {
    .content-container {
      .note-item {
        width: calc((100% - 16px) / 2);

        &.firstItem {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .note-container {
    .content-container {
      .note-item {
        width: calc((100% - 32px) / 3);

        &.firstItem {
          width: calc((100% - 32px) / 3 * 2);

          &.single {
            width: 100%;
          }
        }
      }
    }
  }
}

.empty-container {
  flex-direction: column;

  .empty-img {
    width: 364px;
    height: 214px;
  }

  .empty-title {
    color: #1a202c;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
  }

  .empty-desc {
    color: #718096;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
</style>
